export const UserLoginQuery = [ `
    query UserLogin( $identity: String!, $password: String! )
    {
        user_login( identity: $identity, password: $password )
        {
            id
            token
        }
    }`,
    'user_login' 
];
