import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { GraphQLEndpoint } from './service/GraphQLEndpoint';
import { UserLoginQuery } from './service/GraphQLTransactions';

import './App.css';

import MATTLogo from './assets/logo.svg';
import MATTLineup from './assets/matt_gray_standard.jpg';

const DEBUG = window.location.port === '3000';
const APIHOST = DEBUG ? `${ window.location.hostname }:8080` : window.location.host;
var GQL = new GraphQLEndpoint( `${ window.location.protocol }//${ APIHOST }/sso`, null, false );

function App() 
{
  let [ identity, setIdentity ] = useState( '' );
  let [ password, setPassword ] = useState( '' );
  let [ error, setError ] = useState( false );
  let [ JWT, setJWT ] = useCookies( [ 'token' ] );

  async function handleLogin(  )
  {
    const result = await GQL.fetch( UserLoginQuery, { identity: identity, password: password } );

    if( result === null )
    {
      setError( true );
      return ;
    }

    if( ! result.token )
    {
      setError( true );
      return ;
    } 

    setError( false );
    setJWT( 'token', result.token, { secure: true, path: '/', expires: new Date( Date.now() + 1000 * 30 * 60 * 60 * 24 ) } );
    
    setTimeout( () => window.location.reload(), 250 );
  }

  return (
    <div className={ `d-lg-flex half ${ error ? 'form-err' : '' }` } >
      <div className="bg order-1 order-md-2" style={ { backgroundImage: `url(${MATTLineup})`, backgroundColor: '#383838' } }></div>
      <div className="contents order-2 order-md-1">

        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <h1><img src={MATTLogo} alt="MATT" className='logo-matt'/>Documentation</h1>
              <h2 className="mb-4 subtitle-matt">Extensive documentation and support resources for interacting with any device</h2>
              
              <form action="#" method="post">
                <div className="form-group first">
                  <input type="text" className="form-control" placeholder="Email or username" id="username" value={ identity } onChange={ ( e: any ) => setIdentity( e.target.value ) } />
                </div>
                <div className="form-group last mb-3">
                  <input type="password" className="form-control" placeholder="Password" id="password" value={ password } onChange={ ( e: any ) => setPassword( e.target.value ) } />
                </div>
                
                <input type="button" value="Log In" onClick={ handleLogin } className="btn btn-block btn-success btn-primary btn-login"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
