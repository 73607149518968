class GraphQLEndpoint
{
    public endpoint: String;
    public debug: Boolean;
    private token: String | null;

    constructor( endpoint: String, token: String | null, debug?: Boolean )
    {
        this.endpoint = endpoint;
        this.token = token;
        this.debug = !! debug;

        if( this.debug ) console.log( `${ this } debug enabled` );
    }

    public updateToken( token: String )
    {
        this.token = token;
    }

    public removeToken( )
    {
        this.token = null;
    }
    
    toString(  )
    {
        return `GraphQLEndpoint<${ this.endpoint }, TOKEN=${ ( this.token !== null ) ? '1' : '0' }>`;
    }

    public async fetch( query: String[], variables?: any )
    {
        let headers: any = {            
            'Content-Type': 'application/json',
        };

        if( ! variables ) variables = {};

        // add token
        if( this.token ) 
        {
            headers[ 'Authorization' ] = `bearer ${ this.token }`;
        }

        const response = await fetch( this.endpoint as any, 
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify( {
                query: query[ 0 ],
                variables,
            } ),
        });

        const data = ( await response.json() ).data;

        if( this.debug )
        {
            console.log( `${ this } \n\tfetching query ${ query[ 0 ] } \n\twith ${ JSON.stringify( variables ) }`, '\nsays', 
                !data ? null : data[ query[ 1 ].toString() ] );
        }
        
        if( data === null ) return data;
        
        return data[ query[ 1 ].toString() ];
    }
}

export { GraphQLEndpoint };
